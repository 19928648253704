import React,{ useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from 'react-icons/fa';

export default function App(props) {
  const [isCopied, setIsCopied] = useState(false);


  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
   
          <CopyToClipboard text={props.leftReffLink} onCopy={onCopyText}>
            <p className="pointer mt-1 fw-medium copy_reffral d-flex"><span className="over-hide">{props.leftReffLink}</span> <span className="float-end ms-auto">{isCopied ? "Copied!" : <FaCopy  size={20} />}</span></p>
          </CopyToClipboard>
  );
}