import React,{ useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from 'react-icons/fa';
export default function ConAddCopy() {
  const [isCopied, setIsCopied] = useState(false);
  const codeSnippet = `0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888`;
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
      <>
      <CopyToClipboard text={codeSnippet} onCopy={onCopyText}>
            <p  className="pointer mt-1 fw-medium copy_contadd d-flex"><span className="over-hide">0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888</span> <span className="float-end ms-auto">{isCopied ? "Copied!" : <FaCopy  size={20} />}</span></p>
          </CopyToClipboard>
      </>
   );
  }
