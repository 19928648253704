import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import MetaTrust from '../../Images/MetaTrust.png'
const InstallWalletModal = () => {
      return (
        <>
         <Modal.Body>
          <div className="col-md-12 text-center">
            <img src={MetaTrust} alt="metamask" className="metamask  w-100" />
            <h3 className="font-weight-bold mt-2 text-dark">Metamask | TrustWallet</h3>
            <p className=" text-dark">Install Metamask or TrustWallet to connect</p>
            <Button variant="warning" size="lg"  className="connect_wallet me-3 mt-2" href="https://metamask.io/download/" target="_blank">Click to Install Metamask</Button>
            <Button variant="warning" size="lg"  className="connect_wallet  mt-2" href="https://trustwallet.com/download" target="_blank">Click to Install TrustWallet</Button>
          </div>
        </Modal.Body>
        </>
      )
}
 
export default InstallWalletModal;
