import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import {Navigate , Route , Routes, BrowserRouter } from "react-router-dom";
import AppHome from './App/AppPages/AppHome';
import AppLogin from './App/AppPages/AppLogin';
import './App.css';

function App() { 
  return (
    <>
    <BrowserRouter>
<Routes>
        {/* <Route exact path="/" element={<Home />} /> */}
        <Route path="/" element={<Navigate to="/AppLogin/610042024/" replace />} />
      
        <Route exact path="/AppLogin/:refferal_id/" element={<AppLogin/>} />
        <Route exact path="/AppHome" element={<AppHome />} />
  
        <Route path="/" element={<Navigate to="/" replace />} />
        </Routes>
        </BrowserRouter>
  
    </>
  );
};

 
export default App;