import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap'; 
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Axios from 'axios';
import LRefferal from "./LRefferal";
import RRefferal from "./RRefferal";
import BuySell from "./BuySell";
import ConAddCopy from "./ConAddCopy";
import WalletAddCopy from "./WalletAddCopy";
import Stacking from "./Staking";
import Withdraw from "./Withdraw";

const WalletReward = (props) => {

    const [info, setInfo] = useState([]);

    useEffect(() => {
        Axios.get(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json`)
    
       .then((res) => {
        console.log(res.data.usd.vet);
          setInfo(res.data.usd.vet+res.data.usd.vet*0.036);
          
        })
      });


    return (
      <>
        <section id="WalletReward" className=" px-xxl-5">
            <Container>
                <Row className="">  
                    <Col md={12} className="mt-4">
                        <Row className="">
                        <Col lg="12">
                                <div className="page-title text-right ">
                                <p className="text-left mb-0 fw-medium fs-13 ">Rate</p>
                                    <p className="mt-1 fw-medium connect_wallet text-center ">$1 =  {info} {props.CoinShortName} </p>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="page-title text-right ">
                                    <p className="text-left mb-0 fw-medium fs-13 ">Refferal Link</p>
                                    <LRefferal leftReffLink={props.leftReffLink}/>
                                </div>
                            </Col>
                            
                           
                        <Col lg="4">
                        <div className="page-title text-right">
                                <p className="text-left mb-0 fw-medium fs-13">Wallet Address</p>
                                
                                    <WalletAddCopy currentAccount={props.currentAccount} />
                                </div>
                        </Col>
                            
                            <Col lg="4">
                                <div className="page-title text-right">
                                <p className="text-left mb-0 fw-medium fs-13">Contract Address</p>
                                
                                    <ConAddCopy/>
                                </div>
                            </Col>
                           
                        </Row>
                    </Col> 
               </Row>     
               <Row>
                  
                    <Col md={12}>
                        <Row>
                        
                        <Col md={6}>
                            <Card className=" bggreen ">
                                <Card.Body className="p-3">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                        <span className="badge badge-size-sm bg-soft-success text-white">
                                            <MdOutlineAccountBalanceWallet className="fs-50 text-soft-success"/></span>
                                        </Col>
                                        <Col className="col">
                                        <span className="caption text-white d-block mb-1">Wallet Balance</span>
                                        <span className="h6 mb-0 text-white " id="walletbal">{props.TokenBalance} {props.CoinShortName}</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className=" bggreen">
                                <Card.Body className="p-3">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                        <span className="badge badge-size-sm bg-soft-info text-white">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/bnb.svg'} className="fs-50 text-soft-info" alt="bnb"/></span>
                                        </Col>
                                        <Col className="col">
                                        <span className="caption text-white d-block mb-1">BNB Wallet</span>
                                        <span className="h6 mb-0 text-white " id="walletbal">{props.Balance} BNB</span>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        </Row>
                    </Col>
                   
                      <Col  md={12}>
                      <Stacking vetrate={info} VetBalance={props.TokenBalance} isConnected={props.isConnected}  chainId={props.chainId} isverified={props.isverified} CoinShortName={props.CoinShortName} CoinFullName={props.CoinFullName}/>
                    </Col> 
                    
                    <Col  md={12}>
                      <Withdraw prinicipalamt={props.prinicipalamt} bonusamt={props.bonusamt} vetrate={info} VetBalance={props.TokenBalance} isConnected={props.isConnected}  chainId={props.chainId} isverified={props.isverified} CoinShortName={props.CoinShortName} CoinFullName={props.CoinFullName}/>
                    </Col> 
                </Row>
            </Container>
        </section>
      </>
    );
};
export default WalletReward;