import React, { useState, useEffect } from "react";
import { Button, Modal, Navbar, Nav, Container} from 'react-bootstrap';
import {FaSignInAlt } from 'react-icons/fa';
import { NavLink, Link } from "react-router-dom";
import InstallWalletModal from "./InstallWalletModal";
import Applogo from "../../Images/applogo.png"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
const AppHead = (props) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(window.ethereum);
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setProvider(detectProvider());
  }, []);

  useEffect(() => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          "Not window.ethereum provider.  Do you have multiple wallets installed ?"
        );
      }
      setIsMetaMaskInstalled(true);
    }
  }, [provider]);

  const detectProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.warn("No Ethereum browser detected! Check out MetaMask");
    }
    return provider;
  };

  const onLoginHandler = async () => {
    setIsConnecting(true);
    await provider.request({
      method: "eth_requestAccounts",
    });
    setIsConnecting(false);
    props.onLogin(provider);
    
    setIsConnected(true);
  };
  
    return (
      <>
      <header className="">
        <Container>
      <Navbar bg="transparent" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="#home" className="mr-0"><img src={Applogo} alt="logo" className="logo mw-60px" /></Navbar.Brand>
        <Nav  className="navbar-nav  web-d-none">
           <li className="nav-item">
                      {isMetaMaskInstalled && !isConnected &&  (
                        <Button  size="sm" className="connect_wallet" onClick={onLoginHandler} type="button">
                          {!isConnected && "Login"}
                          {isConnecting && "Loading..."} 
                          <FaSignInAlt className="ms-2"/>
                        </Button>
                      )}  
                      {isMetaMaskInstalled && isConnected && props.chainId==56 && props.isverified==1 && ( 
                      <a as={Link} href={'https://soft.vchaintrade.com/dapp_logins?usercode='+props.currentAccount}   target="_blank" rel="noopener noreferrer" size="sm" className="connect_wallet testt">
                        Dashboard <FaSignInAlt className="ms-2"/></a>)}
                     
                      {isMetaMaskInstalled && isConnected && props.isverified==0 && ( 
                      <Link to="/AppLogin/09292023/LEFT" size="sm" className="connect_wallet testt">
                        Register Now<FaSignInAlt className="ms-2"/></Link>)}

                      {!isMetaMaskInstalled && (
                        <Button  size="lg"  className="connect_wallet" onClick={handleShow} >Install MetaMask</Button>
                      )}
                  </li>
        </Nav>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
          <NavLink  className=" fs-20 menu-btn ms-4  mob-ml-0 mob-mt-15" to="/AppHome"><img className="nav-icon" src={process.env.PUBLIC_URL + '/assets/images/home.svg'} alt="Home Icon" /> HOME</NavLink>
                <a href="https://bscscan.com/address/0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888" className="fs-20 menu-btn ms-4 mob-ml-0 mob-mt-15" target="_blank"  rel="noopener noreferrer"><img className="nav-icon" src={process.env.PUBLIC_URL + '/assets/images/contract.svg'} alt="Contract Icon" /> CONTRACT</a>
            </Nav>
          <Nav  className="navbar-nav mob-d-none">
            <li className="nav-item">
                      {isMetaMaskInstalled && !isConnected &&  (
                        <Button  size="sm" className="connect_wallet" onClick={onLoginHandler} type="button">
                          {!isConnected && "Login"}
                          {isConnecting && "Loading..."} 
                          <FaSignInAlt className="ms-2"/>
                        </Button>
                      )}  
                      {isMetaMaskInstalled && isConnected && props.chainId==56 && props.isverified==1 && ( 
                      <a as={Link} href={'https://soft.vchaintrade.com/dapp_logins?usercode='+props.currentAccount}   target="_blank" rel="noopener noreferrer" size="sm" className="connect_wallet testt">
                        Dashboard <FaSignInAlt className="ms-2"/></a>)}
                     
                      {isMetaMaskInstalled && isConnected && props.isverified==0 && ( 
                      <Link to="/AppLogin/610042024" size="sm" className="connect_wallet testt">
                        Register Now<FaSignInAlt className="ms-2"/></Link>)}

                      {!isMetaMaskInstalled && (
                        <Button  size="lg"  className="connect_wallet" onClick={handleShow} >Install MetaMask</Button>
                      )}
                  </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
        
      
         </header> 
         <Modal show={show} onHide={handleClose} centered>
            <InstallWalletModal/>
        </Modal>
      </>
    );
};
export default AppHead;